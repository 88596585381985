<template>
  <div class="appointSuccess">
    <div class="appointSuccess-t">
      <div class="box">
        <img src="./img/success.png" alt="" />
        你的活动已提交成功
      </div>
    </div>
    <div class="appointSuccess-c">
      <img src="./img/success_bg.png" alt="" />
    </div>
    <div class="goLookBox">
      <div class="box" @click="continueSubmission">继续发布</div>
    </div>
    <div class="goOnBox">
      <div class="box" @click="toLook">去查看</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "publicationSuccess",
  data() {
    return {
      id: null,
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    defOrgId() {
      return this.$store.state.defOrgId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {},
  methods: {
    toLook() {
      this.$router.replace({
        name: "associationActivityList",
        query: {
          id: this.id,
        },
      });
    },
    continueSubmission() {
      this.$router.replace({
        name: "publicationActivity",
        query: {
          id: this.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.appointSuccess {
  background: #fafafa;
  overflow: hidden;
  min-height: 100vh;
  padding-top: 210px;
  box-sizing: border-box;

  .appointSuccess-t {
    width: 100%;

    .box {
      margin: 0 150px;
      font-size: 36px;
      font-weight: 600;
      color: #fe9918;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        margin-right: 14px;
      }
    }
  }

  .appointSuccess-c {
    width: 100%;
    height: 322px;
    margin-top: 124px;
    margin-bottom: 182px;

    img {
      display: block;
      width: 568px;
      margin: 0 auto;
    }
  }

  .goOnBox {
    width: 100%;
    height: 66px;
    padding: 0 100px;
    box-sizing: border-box;
    margin-top: 30px;

    .box {
      box-sizing: border-box;
      text-align: center;
      height: 66px;
      line-height: 66px;
      border-radius: 10px;
      border: 2px solid;
      font-size: 30px;
      font-weight: 600;
      color: #fe9918;
    }
  }

  .goLookBox {
    width: 100%;
    height: 66px;
    padding: 0 100px;
    box-sizing: border-box;

    .box {
      box-sizing: border-box;
      text-align: center;
      height: 66px;
      line-height: 66px;
      font-size: 30px;
      font-weight: 600;
      color: #fff;
      border-radius: 10px !important;
      background: linear-gradient(180deg, #ffbf53 0%, #fe9918 100%);
      border-radius: 10px;
    }
  }
}
</style>
